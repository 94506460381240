import { ElectronicInvoice } from 'src/app/feature/Order/models/Order';
import {
  ADD_ORDER,
  DELETE_ORDER,
  INIT_ORDER,
  GET_ORDERS,
  SET_ELECTRONIC_INVOICE,
  CLEAR_ORDER_STATE,
  TypesActionsOrder
} from '../../actions/order/OrderTypesActions';
import { OrderState } from '../../models/order/OrderState';

const getDataOrder = (): OrderState => {
  const menu = JSON.parse(localStorage.getItem('order'));
  return { order: menu, orders: [] };
};

const initialState: OrderState = localStorage.getItem('order')
  ? getDataOrder()
  : {
      order: {
        orderType: 'en el lugar',
        total: 0,
        foods: [],
        account: '',
        paymentType: '',
        status: '',
        paymentId: '',
        electronicInvoice: null,
        otherPercentage: 0,
        servicePercentage: 0,
        vatPercentage: 0,
        otherFee: 0,
        vatFee: 0,
        discountFee: 0,
        subTotal: 0,
        serviceFee: 0
      },
      orders: []
    };

export default function orderReducer(
  state = initialState,
  action: TypesActionsOrder
) {
  switch (action.type) {
    case ADD_ORDER: {
      const order = action.payload;
      return {
        ...state,
        order
      };
    }
    case SET_ELECTRONIC_INVOICE: {
      const electronicInvoice: ElectronicInvoice = action.payload;
      const nstate = state;
      nstate.order.electronicInvoice = electronicInvoice;
      return {
        ...state,
        ...nstate
      };
    }
    case DELETE_ORDER: {
      return {
        order: initialState
      };
    }
    case INIT_ORDER: {
      return {
        ...state,
        order: {
          total: 0,
          foods: [],
          account: '',
          paymentType: '',
          status: '',
          paymentId: ''
        }
      };
    }
    case GET_ORDERS: {
      let nState = state;
      nState.orders = action.payload;
      state = nState;
      return state;
    }
    case CLEAR_ORDER_STATE: {
      return {
        ...state,
        order: {
          total: 0,
          foods: [],
          account: '',
          paymentType: '',
          status: '',
          paymentId: ''
        },
        orders: []
      };
    }
    default:
      return state;
  }
}

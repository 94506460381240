import {
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  CLEAR_USER_STATE,
  TypesActionsUser
} from '../../actions/users/UsersTypesActions';
import { UserStateAuth } from '../../models/user/UserStateAuth';
import { User } from 'src/app/feature/Auth/Login/models/User';

const getDataUser = (): UserStateAuth => {
  const user = JSON.parse(localStorage.getItem('user'));
  return { user: user };
};

const initialState: UserStateAuth = localStorage.getItem('user')
  ? getDataUser()
  : {
      user: {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: '',
        accessToken: ''
      } as User
    };

export default function userReducer(
  state = initialState,
  action: TypesActionsUser
): UserStateAuth {
  switch (action.type) {
    case ADD_USER: {
      let user = action.payload;
      user.accessToken = action.accessToken;
      return {
        ...state,
        user
      };
    }
    case DELETE_USER: {
      return state;
    }
    case UPDATE_USER: {
      const user = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          billTo: user.billTo
        }
      };
    }
    case CLEAR_USER_STATE: {
      return {
        ...state,
        user: {
          _id: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          role: '',
          accessToken: ''
        }
      };
    }
    default:
      return state;
  }
}

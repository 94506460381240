import {
  GET_CARDS,
  CLEAR_CARDS_STATE,
  TypesActionsCards
} from '../../actions/cards/CardsTypesActions';
import { CardsState } from '../../models/cards/CardsState';

const getDataCards = (): CardsState => {
  const cards = JSON.parse(localStorage.getItem('cards'));
  return { cards: cards };
};

const initialState: CardsState = localStorage.getItem('cards')
  ? getDataCards()
  : {
      cards: []
    };

export default function cardsReducer(
  state = initialState,
  action: TypesActionsCards
) {
  switch (action.type) {
    case GET_CARDS: {
      return {
        ...state,
        cards: action.payload
      };
    }
    case CLEAR_CARDS_STATE: {
      return {
        ...state,
        state: []
      };
    }
    default:
      return state;
  }
}

import { Suspense, lazy } from 'react';
import { PartialRouteObject } from 'react-router';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);
const InitPage = Loader(
  lazy(() => import('src/app/feature/InitPage/pages/InitPage'))
);
const MenuPage = Loader(
  lazy(() => import('src/app/feature/Menu/pages/MenuPage'))
);
const MenuDetailPage = Loader(
  lazy(() => import('src/app/feature/MenuDetail/pages/MenuDetailPage'))
);
const MenuEdit = Loader(
  lazy(() => import('src/app/feature/MenuEdit/pages/MenuEditPage'))
);
const CartPage = Loader(
  lazy(() => import('src/app/feature/Cart/pages/CartPage'))
);
const CheckoutPage = Loader(
  lazy(() => import('src/app/feature/Checkout/pages/CheckoutPage'))
);
const PaymentPage = Loader(
  lazy(() => import('src/app/feature/Payment/pages/PaymentPage'))
);
const ProfilePage = Loader(
  lazy(() => import('src/app/feature/Profile/pages/ProfilePage'))
);
const ProfileEditPage = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);
const CardsPage = Loader(
  lazy(() => import('src/app/feature/Cards/pages/CardsPage'))
);
const OrderPage = Loader(
  lazy(() => import('src/app/feature/Order/pages/OrdersPage'))
);
const OrderDetailPage = Loader(
  lazy(() => import('src/app/feature/OrderDetail/pages/OrderDetailPage'))
);

const routesProtected: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <InitPage />
      },
      {
        path: '/:tableId',
        element: <InitPage />
      },
      {
        path: '/menu/detail/:foodId',
        element: <MenuDetailPage />
      },
      {
        path: '/menu',
        element: <MenuPage />
      },
      {
        path: '/menu/edit/:foodId',
        element: <MenuEdit />
      },
      {
        path: '/order/detail/:orderId',
        element: <OrderDetailPage />
      },
      {
        path: '/cart',
        element: <CartPage />
      },
      {
        path: '/checkout',
        element: <CheckoutPage />
      },
      {
        path: '/profile',
        element: <ProfilePage />
      },
      {
        path: '/profile/edit',
        element: <ProfileEditPage />
      },
      {
        path: '/cards',
        element: <CardsPage />
      },
      {
        path: '/checkout/payment',
        element: <PaymentPage />
      },
      {
        path: '/orders',
        element: <OrderPage />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routesProtected;

import { useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';

import routesAuth from './routesAuth';
import routesProtected from './routesProtected';
import routesAddPhone from './routesAddPhone';
import store from 'src/app/core/redux/store';
import ThemeProvider from './app/shared/theme/ThemeProvider';
import { User } from './app/feature/Auth/Login/models/User';

const App = () => {
  const auth = useRoutes(routesAuth);
  const contentProtected = useRoutes(routesProtected);
  const contentAddPhone = useRoutes(routesAddPhone);

  const getRoutes = () => {
    const storage = localStorage.getItem('user');
    if (!storage) {
      return auth;
    } else {
      const user: User = JSON.parse(storage);
      if (user.phone) {
        return contentProtected;
      } else {
        return contentAddPhone;
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Provider store={store}>{getRoutes()}</Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;

import { Cards } from 'src/app/feature/Cards/models/Cards';

export const GET_CARDS = 'GET_CARDS';
export const CLEAR_CARDS_STATE = 'CLEAR_CARDS_STATE';

interface AccionGetCards {
  type: typeof GET_CARDS;
  payload: Array<Cards>;
}

interface ActionClearCardsState {
  type: typeof CLEAR_CARDS_STATE;
}

export type TypesActionsCards = AccionGetCards | ActionClearCardsState;

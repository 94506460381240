import { ElectronicInvoice, Order } from 'src/app/feature/Order/models/Order';

export const LIST_ORDER = 'LIST_ORDER';
export const INIT_ORDER = 'INIT_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_CATEGORY = 'SET_CATEGORY';
export const GET_ORDERS = 'GET_ORDERS';
export const SET_ELECTRONIC_INVOICE = 'SET_ELECTRONIC_INVOICE';
export const CLEAR_ORDER_STATE = 'CLEAR_ORDER_STATE';

interface AccionAgregarOrder {
  type: typeof ADD_ORDER;
  payload: Order;
}
interface AccionSetCategoria {
  type: typeof SET_CATEGORY;
  payload: string;
}
interface AccionGetOrders {
  type: typeof GET_ORDERS;
  payload: Array<any>;
}
interface AccionSetElectronicInvoice {
  type: typeof SET_ELECTRONIC_INVOICE;
  payload: ElectronicInvoice | null;
}

interface AccionEliminarOrder {
  type: typeof DELETE_ORDER;
}

interface AccionInitOrder {
  type: typeof INIT_ORDER;
}

interface ActionClearOrder {
  type: typeof CLEAR_ORDER_STATE;
}

export type TypesActionsOrder =
  | AccionAgregarOrder
  | AccionSetCategoria
  | AccionInitOrder
  | AccionGetOrders
  | AccionEliminarOrder
  | AccionSetElectronicInvoice
  | ActionClearOrder;

import {
  ADD_USER,
  LIST_USER,
  TypesActionsUser
} from '../../actions/users/UsersTypesActions';
import { UserState } from '../../models/user/UserState';
import { User } from 'src/app/feature/Users/models/User';

const initialState: UserState = {
  users: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  user: {
    _id: '',
    documentId: '',
    country: '',
    state: '',
    city: '',
    address: '',
    zipCode: '',
    dob: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isActive: true,
    account: [],
    role: '',
    status: ''
  } as User
};

export default function usersReducer(
  state = initialState,
  action: TypesActionsUser
) {
  switch (action.type) {
    case LIST_USER: {
      const users = action.payload;
      return {
        ...state,
        users
      };
    }
    case ADD_USER: {
      const user = action.payload;
      return {
        ...state,
        user
      };
    }
    // case UPDATE_USER: {
    //   const user = action.payload;
    //   const index = state.users.docs.findIndex((todo) => todo._id !== user._id);
    //   const newArray = [...state.users.docs]; //making a new array
    //   newArray[index] = action.payload; //changing value in the new array
    //   return {
    //     ...state, //copying the orignal state
    //     users: newArray //reassingning todos to new array
    //   };
    // }
    // case UPLOAD_IMAGE_USER: {
    //   const user = action.payload;
    //   return {
    //     //Hacemos una copia del estado, y enviamos al member recibido
    //     ...state,
    //     user
    //   };
    // }
    default:
      return state;
  }
}

import { User } from 'src/app/feature/Auth/Login/models/User';
import { ResponseUsersProps } from 'src/app/feature/Users/models/User';

export const LIST_USER = 'LIST_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';

interface ListUser {
  type: typeof LIST_USER;
  payload: ResponseUsersProps;
}
interface AccionAgregarUser {
  type: typeof ADD_USER;
  payload: User;
  accessToken: string;
}

interface AccionEliminarUser {
  type: typeof DELETE_USER;
  payload: User;
}
interface ActionActualizarUser {
  type: typeof UPDATE_USER;
  payload: User;
}

interface ActionClearUserState {
  type: typeof CLEAR_USER_STATE;
}

export type TypesActionsUser =
  | ListUser
  | AccionAgregarUser
  | AccionEliminarUser
  | ActionActualizarUser
  | ActionClearUserState;

import { Suspense, lazy } from 'react';
import { PartialRouteObject } from 'react-router';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);
const Home = Loader(lazy(() => import('src/app/feature/Auth/Home/pages/Home')));
const Login = Loader(
  lazy(() => import('src/app/feature/Auth/Login/pages/Login'))
);
const Register = Loader(
  lazy(() => import('src/app/feature/Auth/Register/pages/Register'))
);
const PasswordRecovery = Loader(
  lazy(
    () => import('src/app/feature/Auth/PasswordRecovery/pages/PasswordRecovery')
  )
);

const routesAuth: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/:tableId',
        element: <Home />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login/:tableId',
        element: <Login />
      },
      {
        path: '/register',
        element: <Register />
      },
      {
        path: '/register/:tableId',
        element: <Register />
      },
      {
        path: '/passwordRecovery',
        element: <PasswordRecovery />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routesAuth;

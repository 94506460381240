import {
  ADD_MENU,
  DELETE_MENU,
  SET_CATEGORY,
  CLEAR_MENU_STATE,
  TypesActionsMenu
} from '../../actions/menu/MenuTypesActions';
import { MenuState } from '../../models/menu/MenuState';

const getDataMenu = (): MenuState => {
  const menu = JSON.parse(localStorage.getItem('menu'));
  return { menu: menu };
};

const initialState: MenuState = localStorage.getItem('menu')
  ? getDataMenu()
  : {
      menu: {
        menu: [],
        account: null,
        table: null,
        categories: [],
        foodCategory: null,
        setting: null
      }
    };

export default function menuReducer(
  state = initialState,
  action: TypesActionsMenu
) {
  switch (action.type) {
    case ADD_MENU: {
      const menu = action.payload;
      return {
        ...state,
        menu
      };
    }
    case DELETE_MENU: {
      return {
        menu: initialState
      };
    }
    case SET_CATEGORY: {
      const foodCategory = action.payload;
      let menu = state.menu;
      menu.foodCategory = foodCategory;
      return {
        ...state,
        menu
      };
    }
    case CLEAR_MENU_STATE: {
      return {
        ...state,
        menu: {
          menu: [],
          account: null,
          table: null,
          categories: [],
          foodCategory: null
        }
      };
    }
    default:
      return state;
  }
}

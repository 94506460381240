import { Menu } from 'src/app/feature/Menu/models/Menu';

export const LIST_MENU = 'LIST_MENU';
export const ADD_MENU = 'ADD_MENU';
export const DELETE_MENU = 'DELETE_MENU';
export const UPDATE_MENU = 'UPDATE_MENU';
export const SET_CATEGORY = 'SET_CATEGORY';
export const CLEAR_MENU_STATE = 'CLEAR_MENU_STATE';

interface AccionAgregarMenu {
  type: typeof ADD_MENU;
  payload: Menu;
}
interface AccionSetCategoria {
  type: typeof SET_CATEGORY;
  payload: string;
}

interface AccionEliminarMenu {
  type: typeof DELETE_MENU;
}

interface ActionClearMenuState {
  type: typeof CLEAR_MENU_STATE;
}

export type TypesActionsMenu =
  | AccionAgregarMenu
  | AccionSetCategoria
  | AccionEliminarMenu
  | ActionClearMenuState;

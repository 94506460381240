import { Suspense, lazy } from 'react';
import { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);
lazy(() => import('src/app/feature/Profile/pages/ProfilePage'));
const ProfileEditPage = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);

const routesAddPhone: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/:tableId',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/menu/detail/:foodId',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/menu',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/order/detail/:orderId',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/cart',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/checkout',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/profile',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/profile/edit',
        element: <ProfileEditPage />
      },
      {
        path: '/cards',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/checkout/payment',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '/orders',
        element: <Navigate to="/profile/edit" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routesAddPhone;
